<template>
  <div class="picCharts" ref="picCharts"></div>
</template>

<script>
export default {
  props: {
    chartData: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  methods: {
    resizeHandler() {
      if (this.myChart) {
        this.myChart.resize();
      }
    },
    init() {
      let dom = this.$refs.picCharts;
      if (!this.myChart) {
        this.myChart = this.$echarts.init(dom);
      }

      let option = {
        title: {
          text: this.title,
          top: "10px",
          left: "10px",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          bottom: "bottom",
        },
        series: [
          {
            name: this.title,
            type: "pie",
            radius: "50%",
            data: this.chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      option && this.myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.picCharts {
  width: 500px;
  height: 300px;
  background-color: #fff;
  border-radius: 4px;
}
</style>