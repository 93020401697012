<template>
  <div ref="OrderChart" class="order-chart"></div>
</template>
  
  <script>
export default {
  props: {
    yData: {
      type: Array,
      default: [],
    },
    xData: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      default: "",
    },
    legend: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  methods: {
    resizeHandler() {
      if (this.myChart) {
        this.myChart.resize();
      }
    },
    getSeries() {
      let colorList = ["rgba(153, 108, 244, 1)", "rgba(87, 204, 185, 1)"];
      return this.yData.map((item, index) => {
        return {
          barWidth: 30,
          name: item.name,
          data: item.data,
          type: "bar",
          symbol: "none",
          smooth: false,
          barMinHeight: 1,
          itemStyle: {
            color: colorList[index],
          },
        };
      });
    },
    init() {
      this.$nextTick(() => {
        console.log(this.getSeries());
        console.log(this.legend);
        let dom = this.$refs.OrderChart;
        if (!this.myChart) {
          this.myChart = this.$echarts.init(dom);
        }

        let option = {
          title: {
            text: this.title,
            top: "10px",
            left: "10px",
          },
          legend: {
            show: true,
            orient: "horizontal",
            x: "center",
            y: "top",
            top: '10px',
            data: this.legend,
          },
          tooltip: {
            trigger: "item",
          },
          dataZoom: [
            {
              show: true,
              // height: 8,
              bottom: 0,
              startValue: 0, //起始值
              endValue: 5, //结束值
              // showDetail: false,
              fillerColor: "rgba(1, 132, 213, 0.4)", // 滚动条颜色
              borderColor: "rgba(17, 100, 210, 0.12)",
              backgroundColor: "rgba(221, 220, 107, .1)", //两边未选中的滑动条区域的颜色
              handleSize: 0, //两边手柄尺寸
              showDetail: true, //拖拽时是否展示滚动条两侧的文字
              zoomLock: true, //是否只平移不缩放
              moveOnMouseMove: false, //鼠标移动能触发数据窗口平移
              minValueSpan: 5, // 放大到最少几个
              maxValueSpan: 5, //  缩小到最多几个
            },
            {
              type: "inside", // 支持内部鼠标滚动平移
              start: 0,
              end: 20,
              startValue: 0, // 从头开始。
              endValue: 5, // 最多5个
              zoomOnMouseWheel: false, // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
            },
          ],
          xAxis: {
            type: "category",
            data: this.xData,
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(222, 227, 233, 1)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: "#78797f",
            },
          },
          yAxis: {
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#465A64",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(222, 227, 233, 1)",
              },
            },
            axisLabel: {
              color: "#78797f",
            },
          },
          series: this.getSeries(),
        };

        option && this.myChart.setOption(option);
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.order-chart {
  width: calc(50% - 5px);
  height: 300px;
  background-color: #fff;
  border-radius: 4px;
}
</style>