<template>
  <div ref="TransactionCharts" class="transaction-charts"></div>
</template>
    
    <script>
export default {
  props: {
    yData: {
      type: [Array, Object],
      default: [],
    },
    xData: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  // computed: {
  //   xData() {
  //     let res = [];
  //     for (let i = 0; i < 12; i++) {
  //       res[i] = i + 1 + "月";
  //     }
  //     return res;
  //   },
  //   max() {
  //     let res = this.yData.sort((a, b) => a - b);
  //     return res[0] || null;
  //   },
  // },
  mounted() {},
  methods: {
    resizeHandler() {
      if (this.myChart) {
        this.myChart.resize();
      }
    },
    init() {
      this.$nextTick(() => {
        let dom = this.$refs.TransactionCharts;
        if (!this.myChart) {
          this.myChart = this.$echarts.init(dom);
        }

        let option = {
          title: {
            text: this.title,
            top: "10px",
            left: "10px",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "line", // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: this.xData,
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(222, 227, 233, 1)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: "#78797f",
            },
          },
          yAxis: {
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#465A64",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(222, 227, 233, 1)",
              },
            },
            axisLabel: {
              color: "#78797f",
            },
          },
          legend: {
            origin: "horizontal",
            x: "center",
            y: "bottom",
            itemGap: 5,
            data: ["课件", "问题", "试卷"],
          },
          series: [
            {
              data: this.yData.courseware,
              name: "课件",
              type: "line",
              itemStyle: {
                color: "rgba(84, 112, 198, 1)",
              },
              areaStyle: {
                // ["rgba(22,50,61,1)", "rgba(22,50,61,0.2)"]
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(84, 112, 198, .3)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(84, 112, 198, .1)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            {
              data: this.yData.question,
              name: "问题",
              type: "line",
              symbol: "none",
              smooth: false,
              itemStyle: {
                color: "rgba(145,204,117,1)",
              },
              areaStyle: {
                // ["rgba(22,50,61,1)", "rgba(22,50,61,0.2)"]
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(145,204,117,.3)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(145,204,117,.1)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            {
              data: this.yData.exam,
              name: "试卷",
              type: "line",
              symbol: "none",
              smooth: false,
              itemStyle: {
                color: "rgba(250,200,88,1)",
              },
              areaStyle: {
                // ["rgba(22,50,61,1)", "rgba(22,50,61,0.2)"]
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(250,200,88,.3)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(250,200,88,.1)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          ],
        };

        option && this.myChart.setOption(option);
      });
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.transaction-charts {
  padding: 10px 0;
  width: calc(50% - 5px);
  height: 300px;
  background-color: #fff;
  border-radius: 4px;
}
</style>