<template>
  <div>
    <div class="cell">
      <bar-chart
        ref="employeeChartDOM"
        title="部门员工"
        :yData="employeeYData"
        :xData="employeeXData"
        :legend="['上月', '当月']"
      ></bar-chart>

      <bar-chart
        ref="carChartDOM"
        title="部门车辆"
        :yData="carYData"
        :xData="carXData"
        :legend="['上月', '当月']"
      ></bar-chart>
    </div>

    <div class="cell">
      <line-chart
        ref="hiddenChartDOM"
        title="每月隐患数量"
        :yData="hiddenYData"
        :xData="hiddenXData"
      ></line-chart>

      <line-chart
        ref="dangerChartDOM"
        title="每月风险数量"
        :yData="dangerYData"
        :xData="dangerXData"
      ></line-chart>
    </div>

    <div class="cell">
      <line-chart
        ref="accidentChartDOM"
        title="每月事故数量"
        :yData="accidentYData"
        :xData="accidentXData"
      ></line-chart>
      <line-number-chart
        ref="CQEChartDOM"
        title="每月课件、题目、试卷数量、"
        :yData="CQEYData"
        :xData="CQEXData"
      ></line-number-chart>
    </div>

    <div
      class="cell"
      style="
        display: flex;
        background-color: #fff;
        justify-content: flex-start;
        overflow-x: auto;
        margin-top: 20px;
      "
    >
      <div v-for="(item, index) in pieList" :key="index">
        <pie-chart
          ref="paperChartDOM"
          :chartData="item.data"
          :title="item.title"
        ></pie-chart>
      </div>
    </div>

    <div
      class="cell"
      style="
        display: flex;
        background-color: #fff;
        justify-content: flex-start;
        overflow-x: auto;
        margin-top: 20px;
      "
    >
      <div v-for="(item, index) in coursewareStudyList" :key="index">
        <pie-chart
          ref="coursewareStudyChartDOM"
          :chartData="item.data"
          :title="item.title"
        ></pie-chart>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "./components/line-chart.vue";
import LineNumberChart from "./components/line-number-chart.vue";
import BarChart from "./components/bar-chart.vue";
import PieChart from "./components/pie-chart.vue";

export default {
  name: "page-visual",
  components: {
    LineChart,
    LineNumberChart,
    BarChart,
    PieChart,
  },
  data() {
    return {
      pageData: null,
    };
  },
  computed: {
    hiddenYData() {
      let list = this.pageData?.hiddenNumList || [];
      let new_list = list.map((item) => item.number);
      return new_list;
    },
    hiddenXData() {
      let list = this.pageData?.hiddenNumList || [];
      let new_list = list.map((item) => item.month);
      return new_list;
    },
    dangerYData() {
      let list = this.pageData?.dangerNumList || [];
      let new_list = list.map((item) => item.number);
      return new_list;
    },
    dangerXData() {
      let list = this.pageData?.dangerNumList || [];
      let new_list = list.map((item) => item.month);
      return new_list;
    },
    employeeYData() {
      let list = this.pageData?.employeeNumList || [];
      let new_list = [
        {
          name: "上月",
          data: list.map((item) => item.lastEmployeeNumber),
        },
        {
          name: "当月",
          data: list.map((item) => item.employeeNumber),
        },
      ];
      return new_list;
    },
    employeeXData() {
      let list = this.pageData?.employeeNumList || [];
      let new_list = list.map((item) => item.departmentName);
      return new_list;
    },
    carYData() {
      let list = this.pageData?.carNumList || [];
      let new_list = [
        {
          name: "上月",
          data: list.map((item) => item.lastCarNumber),
        },
        {
          name: "当月",
          data: list.map((item) => item.carNumber),
        },
      ];
      return new_list;
    },
    carXData() {
      let list = this.pageData?.carNumList || [];
      let new_list = list.map((item) => item.departmentName);
      return new_list;
    },
    accidentYData() {
      let list = this.pageData?.accidentNumList || [];
      let new_list = list.map((item) => item.number);
      return new_list;
    },
    accidentXData() {
      let list = this.pageData?.accidentNumList || [];
      let new_list = list.map((item) => item.month);
      return new_list;
    },
    CQEYData() {
      let list = this.pageData?.coursewareQuestionExamList || [];
      function filter_arr(key) {
        let res = list.map((item) => {
          return item[key];
        });
        return res;
      }

      return {
        courseware: filter_arr("coursewareNum"),
        exam: filter_arr("examNum"),
        question: filter_arr("questionNum"),
      };
      //   let new_list = list.map((item) => item.coursewareNum);
      //   return new_list;
    },
    CQEXData() {
      let list = this.pageData?.coursewareQuestionExamList || [];
      let new_list = list.map((item) => item.month);
      return new_list;
    },
    pieList() {
      let arr = this.pageData?.examList || [];
      let new_arr = arr.map((item) => {
        return {
          title: item.examName,
          data: [
            {
              name: "考试人数",
              value: item.testNum,
            },
            {
              name: "未考试人数",
              value: item.notTestNum,
            },
            {
              name: "未合格人数",
              value: item.notPassNum,
            },
            {
              name: "合格人数",
              value: item.passNum,
            },
          ],
        };
      });
      return new_arr;
    },
    coursewareStudyList() {
      let arr = this.pageData?.coursewareStudyList || [];
      let new_arr = arr.map((item) => {
        return {
          title: item.coursewareName,
          data: [
            {
              name: "未学习人数",
              value: item.unStudyNum,
            },
            {
              name: "未学完人数",
              value: item.studyNum,
            },
            {
              name: "已学完人数",
              value: item.studySuccessNum,
            },
          ],
        };
      });
      console.log(new_arr, "new_arr");
      return new_arr;
    },
  },
  created() {
    this.getPageData();
  },
  mounted() {
    window.onresize = () => {
      this.$refs.employeeChartDOM.resizeHandler();
      this.$refs.carChartDOM.resizeHandler();
      this.$refs.hiddenChartDOM.resizeHandler();
      this.$refs.dangerChartDOM.resizeHandler();
      this.$refs.accidentChartDOM.resizeHandler();
      this.$refs.CQEChartDOM.resizeHandler();

      this.$refs.paperChartDOM.forEach((item) => {
        item.init();
      });
      this.$refs.coursewareStudyChartDOM.forEach((item) => {
        item.init();
      });
    };
  },
  methods: {
    getPageData() {
      this.$http.user.chart().then((res) => {
        this.pageData = res?.data || null;
        this.initChart();
      });
    },
    // 初始化chart
    initChart() {
      this.$nextTick(() => {
        this.$refs.employeeChartDOM.init();
        this.$refs.carChartDOM.init();
        this.$refs.hiddenChartDOM.init();
        this.$refs.dangerChartDOM.init();
        this.$refs.accidentChartDOM.init();
        this.$refs.CQEChartDOM.init();

        this.$refs.paperChartDOM.forEach((item) => {
          item.init();
        });
        this.$refs.coursewareStudyChartDOM.forEach((item) => {
          item.init();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  padding: 10px 0;
  //   background-color: #fff;
  display: flex;
  justify-content: space-between;
}
.cell:nth-child(n + 2) {
  margin-top: 20px;
}
</style>