<template>
  <div ref="TransactionCharts" class="transaction-charts"></div>
</template>
  
  <script>
export default {
  props: {
    yData: {
      type: Array,
      default: [],
    },
    xData: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  // computed: {
  //   xData() {
  //     let res = [];
  //     for (let i = 0; i < 12; i++) {
  //       res[i] = i + 1 + "月";
  //     }
  //     return res;
  //   },
  //   max() {
  //     let res = this.yData.sort((a, b) => a - b);
  //     return res[0] || null;
  //   },
  // },
  mounted() {},
  methods: {
    resizeHandler() {
      if (this.myChart) {
        this.myChart.resize();
      }
    },
    init() {
      this.$nextTick(() => {
        let dom = this.$refs.TransactionCharts;
        if (!this.myChart) {
          this.myChart = this.$echarts.init(dom);
        }

        let option = {
          title: {
            text: this.title,
            top: "10px",
            left: "10px",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "line", // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          dataZoom: [
            {
              type: "inside", //1平移 缩放
              throttle: "50", //设置触发视图刷新的频率。单位为毫秒（ms）。
              minValueSpan: 6, //用于限制窗口大小的最小值,在类目轴上可以设置为 5 表示 5 个类目
              start: 1, //数据窗口范围的起始百分比 范围是：0 ~ 100。表示 0% ~ 100%。
              end: 50, //数据窗口范围的结束百分比。范围是：0 ~ 100。
              zoomLock: true, //如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
            },
          ],
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: this.xData,
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(222, 227, 233, 1)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: "#78797f",
            },
          },
          yAxis: {
            type: "value",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#465A64",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(222, 227, 233, 1)",
              },
            },
            axisLabel: {
              color: "#78797f",
            },
          },
          legend: {
            show: false,
          },
          series: [
            {
              data: this.yData,
              type: "line",
              itemStyle: {
                color: "rgba(153, 108, 244, 1)",
              },
              areaStyle: {
                // ["rgba(22,50,61,1)", "rgba(22,50,61,0.2)"]
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(153, 108, 244, .3)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(153, 108, 244, .01)", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          ],
        };

        option && this.myChart.setOption(option);
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.transaction-charts {
  width: calc(50% - 5px);
  height: 300px;
  background-color: #fff;
  border-radius: 4px;
}
</style>